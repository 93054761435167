import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

import { Link } from 'gatsby'
const Nav = (props) => (
    <nav id="nav" className={props.sticky ? 'alt' : ''}>
        <Scrollspy items={ ['intro', 'first', 'second', 'cta'] } currentClassName="is-active" offset={-300}>
            <li>
                <Scroll type="id" element="intro">
                    <a href="#">Test</a>
                </Scroll>
            </li>

            <li>
            <Link to="/game">Book</Link>

            </li>

            <li>
            <Link to="/archetypes">Archetypes</Link>

            </li>

            <li>
                <Link to="/analysis">Global Results</Link>
            </li>

        </Scrollspy>
    </nav>
)

export default Nav

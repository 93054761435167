import React  from 'react'

import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';

class Question extends React.Component{


    handleChange = (e, field) => {
        this.setState({[e.target.name]: e.target.value})
    };


    customHandleChange = (e, field) => {




      this.setState({ [e.target.name]: e.target.value }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state);
      }
    })
    };


    ageQurstion() {
      return(
        <div>
        <label>{this.props.text}</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="1" id={this.props.prefix + "_1"} required /><label  for={this.props.prefix + "_1"}>under 20</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="2" id={this.props.prefix  + "_2"} required /><label for={this.props.prefix + "_2"}>20-30</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="3" id={this.props.prefix  + "_3"} required /><label for={this.props.prefix + "_3"}>30-40</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="4" id={this.props.prefix +  "_4"} required /><label for={this.props.prefix + "_4"}>40-50</label>
        <input type="radio"  onChange={this.customHandleChange}  name={this.props.prefix} value="5" id={this.props.prefix + "_5"} required /><label for={this.props.prefix + "_5"}>over 50</label>
        <hr/>
        </div>
      )
    }

    mbtiIE() {
      return(
        <div>
        <label>{this.props.text}</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="1" id={this.props.prefix + "_1"} required /><label  for={this.props.prefix + "_1"}>Introverted</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="2" id={this.props.prefix  + "_2"} required /><label for={this.props.prefix + "_2"}>Extraverted</label>
        <hr/>
        </div>
      )
    }


    mbtiSI() {
      return(
        <div>
        <label>{this.props.text}</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="1" id={this.props.prefix + "_1"} required /><label  for={this.props.prefix + "_1"}>Sensing</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="2" id={this.props.prefix  + "_2"} required /><label for={this.props.prefix + "_2"}>Intuitive</label>
        <hr/>
        </div>
      )
    }

    mbtiTF() {
      return(
        <div>
        <label>{this.props.text}</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="1" id={this.props.prefix + "_1"} required /><label  for={this.props.prefix + "_1"}>Thinking</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="2" id={this.props.prefix  + "_2"} required /><label for={this.props.prefix + "_2"}>Feeling</label>
        <hr/>
        </div>
      )
    }

    mbtiPJ() {
      return(
        <div>
        <label>{this.props.text}</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="1" id={this.props.prefix + "_1"} required /><label  for={this.props.prefix + "_1"}>Perceiving</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="2" id={this.props.prefix  + "_2"} required /><label for={this.props.prefix + "_2"}>Judging</label>
        <hr/>
        </div>
      )
    }

    mbtiSJW() {
      return(
        <div>
        <label>{this.props.text}</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="1" id={this.props.prefix + "_1"} required /><label  for={this.props.prefix + "_1"}>Male</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="2" id={this.props.prefix  + "_2"} required /><label for={this.props.prefix + "_2"}>Female</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="3" id={this.props.prefix + "_3"} required /><label  for={this.props.prefix + "_3"}>Not sure</label>
        <hr/>
        </div>
      )
    }

    pickRight() {
      if (this.props.prefix == "q73") {
        return (this.ageQurstion());
      } else if (this.props.prefix == "q74") {
          return (this.mbtiIE());
      } else if (this.props.prefix == "q75") {
        return (this.mbtiSI());

      } else if (this.props.prefix == "q76") {
          return (this.mbtiTF());
      } else if (this.props.prefix == "q77") {
          return (this.mbtiPJ());
      } else if (this.props.prefix == "q78") {
          return (this.mbtiSJW());
      }
       else {
        return (this.classicQuestion());
      }

    }

    classicQuestion() {
      return (
        <div>
        <label>{this.props.text}</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="1" id={this.props.prefix + "_1"} required /><label  for={this.props.prefix + "_1"}>1</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="2" id={this.props.prefix  + "_2"} required /><label for={this.props.prefix + "_2"}>2</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="3" id={this.props.prefix  + "_3"} required /><label for={this.props.prefix + "_3"}>3</label>
        <input type="radio" onChange={this.customHandleChange}  name={this.props.prefix} value="4" id={this.props.prefix +  "_4"} required /><label for={this.props.prefix + "_4"}>4</label>
        <input type="radio"  onChange={this.customHandleChange}  name={this.props.prefix} value="5" id={this.props.prefix + "_5"} required /><label for={this.props.prefix + "_5"}>5</label>
        <hr/>
        </div>
      )
    }


  render() {
    return (
      this.pickRight()
    )
  }
}

export default Question;

import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import pic01 from '../assets/images/EmmaAndJung.webp'
import Header from '../components/header'
import Layout from '../components/layout'
import Nav from '../components/Nav'
import ContactForm from '../components/ContactForm'

import { FaTwitter} from "react-icons/fa";
import {FaFacebook} from "react-icons/fa";
import {FaGooglePlus }from "react-icons/fa";
import {FaPinterest }from "react-icons/fa";
import {FaEnvelope }from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { ShareButtonRectangle, ShareBlockStandard } from "react-custom-share";


class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Find your archetype" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav  />

        <div id="main">


          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Find your archetype</h2>
                </header>
                <p>
                  <p>Jungian archetypes are defined as universal, archaic symbols and images that derive from the collective unconscious, as proposed by Carl Jung. They are the psychic counterpart of instinct. That is to say they are a kind of innate unspecific knowledge, derived from the sum total of human history, which prefigures and directs conscious behavior. </p>

                  <p>It is especially important to picture the archetypes of the unconscious not as a rushing phantasmagoria of fugitive images but as constant, autonomous factors, which indeed they are.</p>

                  In the test below, you can discover the strongest archetype of your personality, based on the HMI metric.
                </p>
                <ul className="actions">
                  <li>
                    <Link to="/archetypes" className="button">
                      Learn More About Archetypes
                    </Link>
                  </li>
                </ul>
              </div>
              <span className="image">
                <img src={pic01} alt="Carl Jung with his wife, Emma" title="Carl Jung with his wife, Emma" />
              </span>
            </div>
          </section>


          <section id="contactUs" className="main special">
              <header className="major">
          <h2>Archetype test</h2>

          <div align="left">
          <b>Meaning of values:</b>
          <ol>
          <li>Almost never descriptive of me</li>

<li>Rarely descriptive of me</li>
<li>Sometimes descriptive of me</li>
<li>Usually descriptive of me</li>
<li>Almost always descriptive of me</li>
    </ol>
      <h3>Answer all non-optional questions:</h3>
    <hr/>

          </div>
          <ContactForm ></ContactForm>

          </header>
          </section>








        </div>
      </Layout>
    )
  }
}

export default Index

import React from 'react'
import logo from '../assets/images/archetypes/MJ/pleroma.webp';

const Header = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={logo} alt="" /></span>
        <h1>Discover your 12 Jungian archetypes</h1>
        <p>Find your Jungian archetype for free. It takes 5 minutes.</p>
    </header>
)

export default Header

import React from "react";
import axios from "axios";
import Question from './radios'
import { Link } from "gatsby"
import Radio from '@material-ui/core/Radio';

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = this.handleChange;
    this.state = {
      archetype: "unknown - error occured",
      innocent: 0,
      caregiver: 0,
      destroyer: 0,
      ruler: 0,
      orphan: 0,
      seeker: 0,
      creator: 0,
      sage: 0,
      warrior: 0,
      lover: 0,
      magician: 0,
      fool: 0,
      vals_arr: [],
      names_arr: [],
      best: "",
      ok: false,
      age: -1,
      mbti1: "x",
      mbti2: "x",
      mbti3: "x",
      mbti4: "x",
      gender: "y",
    };
  }


  handleFormSubmit = e => {

    //e.preventDefault();

    console.log("submitted")


  };

  componentDidUpdate() {
  }


  //handle changing fields

    handleChange = (e, field) => {

      this.setState(e);
      this.calculateArchetype();
      //this.setState({[e.target.name]: e.target.value})
    };

    everthingOK(vals_arr) {
        this.setState(
        { ok: !vals_arr.includes(NaN)}
      );
    };


    calculateArchetype() {
        var innocent =    parseInt(this.state.q5) +   parseInt(this.state.q13) +parseInt(this.state.q34) +parseInt(this.state.q49) +parseInt(this.state.q63) +parseInt(this.state.q65);
        var caregiver =   parseInt(this.state.q7) +   parseInt(this.state.q10) +parseInt(this.state.q15) +parseInt(this.state.q24) +parseInt(this.state.q55) +parseInt(this.state.q68);
        var destroyer =   parseInt(this.state.q2) +   parseInt(this.state.q4) + parseInt(this.state.q21) +parseInt(this.state.q52) +parseInt(this.state.q61) +parseInt(this.state.q66);
        var ruler =       parseInt(this.state.q26) +  parseInt(this.state.q32) +parseInt(this.state.q35) +parseInt(this.state.q38) +parseInt(this.state.q46) +parseInt(this.state.q67);
        var orphan =      parseInt(this.state.q14) +  parseInt(this.state.q22) +parseInt(this.state.q27) +parseInt(this.state.q30) +parseInt(this.state.q50) +parseInt(this.state.q71);
        var seeker =      parseInt(this.state.q33) +  parseInt(this.state.q47) +parseInt(this.state.q51) +parseInt(this.state.q62) +parseInt(this.state.q70) +parseInt(this.state.q72);
        var creator  =    parseInt(this.state.q8) +   parseInt(this.state.q19) +parseInt(this.state.q31) +parseInt(this.state.q60) +parseInt(this.state.q64) +parseInt(this.state.q69);
        var sage =        parseInt(this.state.q1) +   parseInt(this.state.q18) +parseInt(this.state.q20) +parseInt(this.state.q36) +parseInt(this.state.q41) +parseInt(this.state.q56);
        var warrior =     parseInt(this.state.q6) +   parseInt(this.state.q39) +parseInt(this.state.q40) +parseInt(this.state.q44) +parseInt(this.state.q57) +parseInt(this.state.q59);
        var lover =       parseInt(this.state.q12) +  parseInt(this.state.q16) +parseInt(this.state.q17) +parseInt(this.state.q25) +parseInt(this.state.q29) +parseInt(this.state.q45);
        var magician =    parseInt(this.state.q3) +   parseInt(this.state.q23) +parseInt(this.state.q37) +parseInt(this.state.q42) +parseInt(this.state.q48) +parseInt(this.state.q58);
        var fool =        parseInt(this.state.q9) +   parseInt(this.state.q11) +parseInt(this.state.q28) +parseInt(this.state.q43) +parseInt(this.state.q53) +parseInt(this.state.q54);

        var vals_arr = [innocent, caregiver, destroyer, ruler, orphan, seeker, creator, sage, warrior, lover, magician, fool]
        var names_arr = ["innocent", "caregiver", "destroyer", "king", "orphan", "seeker", "creator", "sage", "warrior", "lover", "magician", "fool"]
        var indexOfMaxValue = vals_arr.reduce((iMax, x, i, arr) => x > arr[iMax] ? i : iMax, 0);

        this.setState({
          vals_arr: vals_arr,
          names_arr: names_arr,
          best: names_arr[indexOfMaxValue],
          innocent:innocent,
          caregiver: caregiver,
          destroyer: destroyer,
          ruler: ruler,
          orphan: orphan,
          seeker: seeker,
          creator: creator,
          sage: sage,
          warrior: warrior,
          lover: lover,
          magician: magician,
          fool: fool,
          age: this.state.q73,
          mbti1: this.state.q74,
          mbti2: this.state.q75,
          mbti3: this.state.q76,
          mbti4: this.state.q77,
          gender: this.state.q78,
        });

        this.everthingOK(vals_arr);

    }





  render() {

    return (
      <div className="form-wrapper">
        <div>
          <form action={this.state.best}>
          <Question text="I collect information without making judgments." prefix="q1" onChange={this.myRef}/>
          <Question text="I feel disoriented by so much change in my life." prefix="q2" onChange={this.myRef}/>
          <Question text="The process of my own self-healing enables me to help heal others." prefix="q3" onChange={this.myRef}/>
          <Question text="I have let others down." prefix="q4" onChange={this.myRef}/>
          <Question text="I feel safe." prefix="q5" onChange={this.myRef}/>
          <Question text="I put fear aside and do what needs to be done." prefix="q6" onChange={this.myRef}/>
          <Question text="I put the needs of others before my own." prefix="q7" onChange={this.myRef}/>
          <Question text="I try to be authentic wherever I am." prefix="q8" onChange={this.myRef}/>
          <Question text="When life gets dull, I like to shake things up." prefix="q9" onChange={this.myRef}/>
          <Question text="I find satisfaction caring for others." prefix="q10" onChange={this.myRef}/>
          <Question text="Others see me as fun." prefix="q11" onChange={this.myRef}/>
          <Question text="I feel sexy." prefix="q12" onChange={this.myRef}/>
          <Question text="I believe that people don’t really mean to hurt each other." prefix="q13" onChange={this.myRef}/>
          <Question text="As a child, I was neglected or victimized." prefix="q14" onChange={this.myRef}/>
          <Question text="Giving makes me happier than receiving." prefix="q15" onChange={this.myRef}/>
          <Question text='I agree with the statement, “It is better to have loved and lost than never to have loved at all.”' prefix="q16" onChange={this.myRef}/>
          <Question text="I embrace life fully." prefix="q17" onChange={this.myRef}/>
          <Question text="I keep a sense of perspective by taking a long-range view." prefix="q18" onChange={this.myRef}/>
          <Question text="I am in the process of creating my own life." prefix="q19" onChange={this.myRef}/>
          <Question text="I believe there are many good ways to look at the same thing." prefix="q20" onChange={this.myRef}/>
          <Question text="I am no longer the person I thought I was." prefix="q21" onChange={this.myRef}/>
          <Question text="Life is one heartache after another." prefix="q22" onChange={this.myRef}/>
          <Question text="Spiritual help accounts for my effectiveness." prefix="q23" onChange={this.myRef}/>
          <Question text="I find it easier to do for others than to do for myself." prefix="q24" onChange={this.myRef}/>
          <Question text="I find fulfillment through relationships." prefix="q25" onChange={this.myRef}/>
          <Question text="People look to me for direction." prefix="q26" onChange={this.myRef}/>
          <Question text="I fear those in authority." prefix="q27" onChange={this.myRef}/>
          <Question text="I don’t take rules too seriously." prefix="q28" onChange={this.myRef}/>
          <Question text="I like to help people connect with one another." prefix="q29" onChange={this.myRef}/>
          <Question text="I feel abandoned." prefix="q30" onChange={this.myRef}/>
          <Question text="I have times of high accomplishment that feel effortless to me." prefix="q31" onChange={this.myRef}/>
          <Question text="I have leadership qualities." prefix="q32" onChange={this.myRef}/>
          <Question text="I am searching for ways to improve myself." prefix="q33" onChange={this.myRef}/>
          <Question text="I can count on others to take care of me." prefix="q34" onChange={this.myRef}/>
          <Question text="I prefer to be in charge." prefix="q35" onChange={this.myRef}/>
          <Question text="I try to find truths behind illusions." prefix="q36" onChange={this.myRef}/>
          <Question text="Changing my inner thoughts changes my outer life." prefix="q37" onChange={this.myRef}/>
          <Question text="I develop resources, human or natural." prefix="q38" onChange={this.myRef}/>
          <Question text="I am willing to take personal risks in order to defend my beliefs." prefix="q39" onChange={this.myRef}/>
          <Question text="I can’t sit back and let a wrong go by without challenging it." prefix="q40" onChange={this.myRef}/>
          <Question text="I strive for objectivity." prefix="q41" onChange={this.myRef}/>
          <Question text="My presence is often a catalyst for change." prefix="q42" onChange={this.myRef}/>
          <Question text="I enjoy making people laugh." prefix="q43" onChange={this.myRef}/>
          <Question text="I use discipline to achieve goals." prefix="q44" onChange={this.myRef}/>
          <Question text="I feel loving toward people in general." prefix="q45" onChange={this.myRef}/>
          <Question text="I am good at matching people’s abilities with tasks to be done." prefix="q46" onChange={this.myRef}/>
          <Question text="It is essential for me to maintain my independence." prefix="q47" onChange={this.myRef}/>
          <Question text="I believe everyone and everything in the world are interconnected." prefix="q48" onChange={this.myRef}/>
          <Question text="The world is a safe place." prefix="q49" onChange={this.myRef}/>
          <Question text="People I’ve trusted have abandoned me." prefix="q50" onChange={this.myRef}/>
          <Question text="I feel restless." prefix="q51" onChange={this.myRef}/>
          <Question text="I am letting go of things that do not fit for me anymore." prefix="q52" onChange={this.myRef}/>
          <Question text="I like to “lighten up” people who are too serious." prefix="q53" onChange={this.myRef}/>
          <Question text="A little chaos is good for the soul." prefix="q54" onChange={this.myRef}/>
          <Question text="Sacrificing to help others has made me a better person." prefix="q55" onChange={this.myRef}/>
          <Question text="I am calm." prefix="q56" onChange={this.myRef}/>
          <Question text="I stand up to offensive people." prefix="q57" onChange={this.myRef}/>
          <Question text="I like to transform situations." prefix="q58" onChange={this.myRef}/>
          <Question text="The key to success in all aspects of life is discipline." prefix="q59" onChange={this.myRef}/>
          <Question text="Inspiration comes easily to me." prefix="q60" onChange={this.myRef}/>
          <Question text="I do not live up to my expectations for myself." prefix="q61" onChange={this.myRef}/>
          <Question text="I have a sense that a better world awaits me somewhere." prefix="q62" onChange={this.myRef}/>
          <Question text="I assume that people I meet are trustworthy." prefix="q63" onChange={this.myRef}/>
          <Question text="I am experiencing with turning my dreams into realities." prefix="q64" onChange={this.myRef}/>
          <Question text="I know my needs will be provided for." prefix="q65" onChange={this.myRef}/>
          <Question text="I feel like breaking something." prefix="q66" onChange={this.myRef}/>
          <Question text="I try to manage situations with the good of all in mind." prefix="q67" onChange={this.myRef}/>
          <Question text="I have a hard time saying no." prefix="q68" onChange={this.myRef}/>
          <Question text="I have a lot more great ideas than I have time to act on them." prefix="q69" onChange={this.myRef}/>
          <Question text="I am looking for greener pastures." prefix="q70" onChange={this.myRef}/>
          <Question text="Important people in my life have let me down." prefix="q71" onChange={this.myRef}/>
          <Question text="The act of looking for something is as important to me as finding it." prefix="q72" onChange={this.myRef}/>
          <Question text="[Optional] Are you Introverted or Extraverted? (for statistical purposes)." prefix="q74" onChange={this.myRef}/>
          <Question text="[Optional] Are you Sensing or Intuitive? (for statistical purposes)." prefix="q75" onChange={this.myRef}/>
          <Question text="[Optional] Are you Thinker or Feeler? (for statistical purposes)." prefix="q76" onChange={this.myRef}/>
          <Question text="[Optional] Are you Perceiving or Judging? (for statistical purposes)." prefix="q77" onChange={this.myRef}/>
          <Question text="Enter your gender (for statistical purposes)." prefix="q78" onChange={this.myRef}/>
          <Question text="Enter your age (for statistical purposes)." prefix="q73" onChange={this.myRef}/>
          

          </form>
        </div>

        {this.state.ok ? (
          <div>
          <Link
            to={this.state.best + "?" + this.state.mbti1 + this.state.mbti2 + this.state.mbti3 + this.state.mbti4 + "&" + this.state.gender}
            state = {this.state}
            activeStyle={{ color: "red" }}
            partiallyActive={true}
            class="button"
          >Find your archetypes</Link>
          </div>
        ) : (
          <div>
          Submit button will appear here once you fill in all non-optional questions


          </div>
        )}

      </div>
    );
  }
}

export default ContactForm;
